@import "./variables";

.screenHeight {
	position: fixed;
	padding: 0;
	margin: 0;
	top: 0;
	left: 0;
	min-width: 100%;
	min-height: 100%;
}

.font-color-white {
	color: var(--color-white) !important;
}

.font-color-red {
	color: var(--color-red) !important;
}

.font-size-medium {
	font-size: var(--font-medium) !important;
}

.app-playground {
	height: calc(100%) !important;
	width: 100% !important;
}

.height-100 {
	height: 100% !important;
}

.blur {
	background: rgba(74, 74, 74, 0.5) !important;
	backdrop-filter: blur(20px) !important;
	-webkit-backdrop-filter: blur(20px) !important;
}

.power-button {
	.ms-Button-flexContainer {
		.ms-Icon {
			font-size: 25px !important;
		}
	}
}

.blue-screen {
	background-color: var(--color-blue) !important;
}
