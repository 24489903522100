// https://codepen.io/Mobius1/pen/aNVyzp

$duration: 3000ms;
$loader-size: 50px;
$pip-size: 8px;

h1 {
	font-weight: 300;
}

.wrapper {
	text-align: center;
}

.loader {
	position: relative;
	width: $loader-size;
	height: $loader-size;
	border-radius: 50%;
	animation: $duration * 1.5 linear 0s normal none infinite running;
	animation-name: rotate;
	left: $loader-size / 2;
}
.loader span::after {
	position: absolute;
	width: $pip-size;
	height: $pip-size;
	border-radius: 50%;
	background-color: #fff;
	content: "";
	display: block;
	transform-origin: $loader-size/2 $loader-size/2;
	animation-duration: $duration;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@for $i from 0 through 5 {
	$start: $i * 20;
	$delay: 900 - ($i * 125);
	$mid: 180 + $start;
	$finish: 360 + $start;

	.pip-#{$i} {
		&::after {
			transform: rotate(#{$start}deg);
			animation-name: rotate-#{$i};
			animation-delay: #{$delay}ms;
		}
	}

	@keyframes rotate-#{$i} {
		0%,
		20% {
			transform: rotate(#{$start}deg);
		}
		40%,
		60% {
			transform: rotate(#{$mid}deg);
		}
		80%,
		100% {
			transform: rotate(#{$finish}deg);
		}
	}
}

@keyframes opacity {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
